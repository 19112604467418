// import $http from '@/utils/http'
import request from '@/utils/request'
const url =
  'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimage01.71.net%2Fimage01%2F40%2F97%2F05%2F64%2F3afa4902-87a2-4c4d-887b-f6cdb9445613.jpg&refer=http%3A%2F%2Fimage01.71.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634221109&t=f64abe536220bfc4e529b192d158c68d'

// 经营户档案
export function getList() {
  return request({
    url: '/publicity/stall',
    method: 'get'
  })
}
export function getDetail(id) {
  return request({
    url: '/publicity/stall/detail/' + id,
    method: 'get'
  })
}
export function getTrace(id) {
  return request({
    url: '/stall/trace/' + id, 
    method: 'get'
  })
}
export function getPublicity() {
  return request({
    url: `/publicity/market/trace`,
    method: 'get'
  })
}

// 获取经营户详情
// export function getDetail() {
//   return new Promise((resolve) => {
//     const detail = {
//       param1: '王僧',
//       param2: '畜肉类',
//       param3: '37',
//       param4: '13344445555',
//       param5: '2',
//       param6: '100',
//       param7: url,
//       param8: url,
//       param9: url,
//       param10: url,
//     }
//     setTimeout(() => {
//       resolve(detail)
//     }, 300)
//   })
// }

// export function getPublicity() {
//   return new Promise((resolve) => {
//     const list = [
//       {
//         param1: '王僧',
//         param2: '37',
//         param3: '90',
//         param4: '畜肉类',
//         param5: '2020-02-21',
//         param6: url,
//       },
//       {
//         param1: '王僧',
//         param2: '37',
//         param3: '90',
//         param4: '畜肉类',
//         param5: '2020-02-21',
//         param6: url,
//       },
//       {
//         param1: '王僧',
//         param2: '37',
//         param3: '90',
//         param4: '畜肉类',
//         param5: '2020-02-21',
//         param6: url,
//       },
//       {
//         param1: '王僧',
//         param2: '37',
//         param3: '90',
//         param4: '畜肉类',
//         param5: '2020-02-21',
//         param6: url,
//       },
//       {
//         param1: '王僧',
//         param2: '37',
//         param3: '90',
//         param4: '畜肉类',
//         param5: '2020-02-21',
//         param6: url,
//       },
//       {
//         param1: '王僧',
//         param2: '37',
//         param3: '90',
//         param4: '畜肉类',
//         param5: '2020-02-21',
//         param6: url,
//       },
//       {
//         param1: '王僧',
//         param2: '37',
//         param3: '90',
//         param4: '畜肉类',
//         param5: '2020-02-21',
//         param6: url,
//       },
//       {
//         param1: '王僧',
//         param2: '37',
//         param3: '90',
//         param4: '畜肉类',
//         param5: '2020-02-21',
//         param6: url,
//       },
//       {
//         param1: '王僧',
//         param2: '37',
//         param3: '90',
//         param4: '畜肉类',
//         param5: '2020-02-21',
//         param6: url,
//       },
//     ]
//     setTimeout(() => {
//       resolve(list)
//     }, 300)
//   })
// }
