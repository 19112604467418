<template>
  <Tab :list="tabList" :current="0" />
  <div class="flex-1 fff flex-col">
    <van-nav-bar
      title="商户基本信息"
      left-text="返回"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <van-row class="row">
      <van-col span="12" class="col">
        <span class="label">经营户：</span> {{ detail.userName }}
      </van-col>
      <van-col span="12" class="col">
        <span class="label">经营种类：</span> {{ detail.stallScopeName }}
      </van-col>
      <van-col span="12" class="col">
        <span class="label">摊位号：</span> {{ detail.stallName }}
      </van-col>
      <van-col span="12" class="col">
        <span class="label">联系电话：</span> {{ detail.mobile }}
      </van-col>
      <van-col span="12" class="col">
        <span class="label">索证张数：</span> 
        {{ (detail.entryPath ? 1 : 0) + (detail.licensePath ? 1 : 0) + (detail.permitPath ? 1 : 0) + (detail.registrationPath ? 1 : 0)}}
      </van-col>
      <van-col span="12" class="col">
        <span class="label">信用评分：</span> {{ detail.creditRank }}分
      </van-col>
    </van-row>
    <van-row class="row flex-1 imgs">
      <van-col span="10" offset="1" class="col flex-col">
        <img
          class="flex-1"
          :src="detail.licensePath"
          @click="() => showImg(detail.licensePath)"
        />
        <span>营业执照</span>
      </van-col>
      <van-col span="10" offset="2" class="col flex-col">
        <img
          class="flex-1"
          :src="detail.registrationPath"
          @click="() => showImg(detail.registrationPath)"
        />
        <span>市场登记证</span>
      </van-col>
      <van-col span="10" offset="1" class="col flex-col">
        <img
          class="flex-1"
          :src="detail.permitPath"
          @click="() => showImg(detail.permitPath)"
        />
        <span>经营许可证</span>
      </van-col>
      <van-col span="10" offset="2" class="col flex-col">
        <img
          class="flex-1"
          :src="detail.entryPath"
          @click="() => showImg(detail.entryPath)"
        />
        <span>入场协议</span>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { traceabilityTabList } from '@/utils/tab'
import { useRouter, useRoute } from 'vue-router'
import { reactive, ref } from '@vue/reactivity'
import Tab from '@/components/tab/index.vue'
import { ImagePreview } from 'vant'
import { getDetail } from '@/api/traceability'

export default {
  components: {
    Tab,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const id = route.query.id
    const onClickLeft = () => {
      router.back()
    }

    const tabList = reactive(traceabilityTabList)

    const showImg = (img) => {
      ImagePreview([img])
    }

    let detail = ref({})
    getDetail(id).then((res) => {
      detail.value = res.result
    })

    return {
      onClickLeft,
      tabList,
      showImg,
      detail,
    }
  },
}
</script>

<style lang="less" scoped>
.row {
  padding: 12px;
  .col {
    display: flex;
    align-items: center;
    color: #666;
    line-height: 24px;
  }
  .label {
    width: 90px;
    text-align: right;
    color: #333;
  }
}
.detail {
  padding: 16px;
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
  .time {
    text-align: center;
    color: #666;
    font-size: 14px;
  }
  .content {
    border-top: 1px solid #e5e5e5;
  }
}

.imgs {
  img {
    width: 100%;
  }
  span {
    line-height: 40px;
  }
}
</style>
